<template>
  <div class="box">
    <div class="tabbar" :class="navBarFixed ? 'navBarFixed': ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="">
    </div>
    <img class="banner" src="/subject/report/banner.png" alt="">
    <div class="content">
      <div class="part part_1">
        <img style="margin-top:-30px;" src="/subject/report/title1.png" alt="">
        <div class="videoFrame">
          <video class="craig" v-if="!ckplayerObject.live" :src="isVideo" controls="controls" autoplay="autoplay" muted></video>
          <video v-if="ckplayerObject.live" id="videoPlayer" class="video-js" muted></video>
        </div>
        <img style="margin-top:-150px;" src="/subject/report/team.png" alt="">
        <p>
          <img @click="openLive" src="/subject/report/live800.png" alt="">
          <img @click="openLogin" src="/subject/report/loginImg.png" alt="">
        </p>
      </div>
      <div class="part part_2">
        <img src="/subject/report/title2.png" alt="">
        <p class="teacher">
          <img src="/subject/report/t1.png" alt="">
          <img src="/subject/report/t2.png" alt="">
          <img src="/subject/report/t3.png" alt="">
        </p>
        <img style="margin-top:70px;" src="subject/report/part2Banner.png" alt="">
        <p class="button">
          <img @click="openLive" src="/subject/report/live800.png" alt="">
          <img @click="openLogin" src="/subject/report/loginImg.png" alt="">
        </p>
      </div>
      <div class="part part_3">
        <img src="/subject/report/title3.png" alt="">
        <img style="margin-top:-30px;margin-bottom: 100px;" src="/subject/report/timeTable.png" alt="">
        <p>
          <img @click="openLive" src="/subject/report/live800.png" alt="">
          <img @click="openLogin" src="/subject/report/loginImg.png" alt="">
        </p>
      </div>
      <div class="part part_4">
        <img class="book" src="/subject/report/book.png" alt="">
        <img src="/subject/report/title4.png" alt="">
        <img style="margin:-450px 0 -330px;" src="/subject/report/stage.png" alt="">
        <img src="/subject/report/meyx.png" alt="">
        <p>
          <img @click="openLive" src="/subject/report/live800.png" alt="">
          <img @click="openLogin" src="/subject/report/loginImg.png" alt="">
        </p>
      </div>
      <div class="part part_5">
        <img src="/subject/report/title5.png" alt="">
        <img src="/subject/report/media.png" alt="">
      </div>
    </div>
    <Contactus></Contactus>
  </div>
</template>

<script>
import Contactus from '../../Contactus.vue';
import {livingRoomNow} from '../../../../api/info'
import videojs from 'video.js';
export default {
  name:'reportPC',
  components:{
    Contactus,
  },
  data(){
    return{
      screenWidth:null,
      navBarFixed: false,
      scroll: Number,
      isVideo:'https://www.rlcvipltd.net/static/video/v-0320.mp4',
      timer:null,
      myPlyer:null,
      options:null,
    }
  },
  created(){
    // 传给App.vue,展示tabbar
    this.$emit('controlShow',true);
    this.init();
    this.timer = window.setInterval(() => {
      setTimeout(this.init(), 0)
    }, 1000*10)
  },
  beforeDestroy() {
    //在 beforeDestroy生命周期函数中销毁定时器
    clearInterval(this.timer);
    this.timer = null;
  },
  computed:{
    ckplayerObject() {
      return this.$store.state.home.ckplayerObject
    },
  },
  methods:{
    // 获取是否直播以及直播流
    init(){
      livingRoomNow({platid:1}).then(res=>{
        if(res.Status == 0 && res.content.length>0){
          this.$store.commit('home/set_ckplayerObject',{video: res.content[0].param.pcurl ,live: true})
          this.isVideo = res.content[0].param.pcurl;
          setTimeout(()=>{
            this.initVideo(this.isVideo);
          },500)
        }else{
          this.$store.commit('home/set_ckplayerObject',{video: 'https://www.rlcvipltd.net/static/video/v-0320.mp4' , live: false})
          this.isVideo = 'https://www.rlcvipltd.net/static/video/v-0320.mp4'; 
          // this.myPlyer.dispose();
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    // 控制顶部导航栏的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
    // 返回上级页面
    goback(){
      window.location.href = window.location.origin;
    },
    openLogin() {
      window.open("https://www.rlcvipltd.net/uc/login");
      // console.log(this.ckplayerObject);
    },
    openLive() {
      window.open(
        this.$parent.live800UrlUc
      );
    },
    // 初始化videojs内容
    initVideo(nowPlayVideoUrl) {
      // 这些options属性也可直接设置在video标签上，见 muted
      this.options = {
        autoplay: true, // 设置自动播放
        controls: true, // 显示播放的控件
        sources: [
        // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
          {
          src: nowPlayVideoUrl,
          type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
          }
        ]
      };
      // videojs的第一个参数表示的是，文档中video的id
      this.myPlyer = videojs("videoPlayer", this.options, function onPlayerReady() {
      });
    },
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })()
    };
  },
  watch: {
    screenWidth: function (n) {
      if (n <= 500) {
        this.$router.push({
          name: "reportWap",
        });
      }
    },
  },
}
</script>

<style lang="less" scoped>
.box{
  width: 100%;
  height: auto;
  min-width: 1640px;
  .tabbar{
    width: 100%;
    background-color: #ffffff;
    img{
      margin: 20px 50px;
      width: 130px;
    }
  }
  .navBarFixed{
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .banner{
    width: 100%;
    display: block;
  }
  .content{
    width: 100%;
    height: 7560px;
    background: url('/subject/report/background.jpg') no-repeat;
    background-size: 100% 100%;
    .part{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .part_1{
      .videoFrame{
        width: 1900px;
        height: 963px;
        background: url('/subject/report/videoFrame.png') no-repeat;
        background-size: 100% 100%;
        margin-top: -50px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .craig{
          width: 1030px;
          height: 585px;
          margin-top: 30px;
        }
      }
      p{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          margin: 0 20px;
          cursor: pointer;
        }
      }
    }
    .part_2{
      .teacher{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -20px;
      }
      img{
        margin: 0 18px;
        cursor: pointer;
      }
      .button{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          margin: 0 20px;
          cursor: pointer;
        }
      }
    }
    .part_3{
      margin-top: 80px;
      position: relative;
      p{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,0);
        z-index: 3;
        img{
          margin: 0 20px;
          cursor: pointer;
        }
      }
    }
    .part_4{
      margin-top: 20px;
      position: relative;
      .book{
        width: 335px;
        position: absolute;
        top: 46.4%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      p{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -20px;
        img{
          margin: 0 20px;
          cursor: pointer;
        }
      }
    }
    .part_5{
      margin-top: 50px;
    }
  }
}
/deep/ .videoPlayer-dimensions{
  width: 1030px;
  height: 585px;
  margin-top: 30px;
}
</style>